import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import tw from "twin.macro" //eslint-disable-line
import { css } from "styled-components/macro" //eslint-disable-line
import BackIcon from "react-feather/dist/icons/chevron-left"

import { StaticLayout } from "../components/StaticLayout"

const OrderSuccessPage = ({ location }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  useEffect(() => {
    localStorage.setItem("cart", "{}")
    const query = new URLSearchParams(location.search)
    getOrderDetails(query.get("session_id"))
  }, [])

  /** Query data from Stripe */
  const getOrderDetails = async sessionId => {
    fetch(
      "/.netlify/functions/getSession?session_id=" + sessionId
    )
      .then(async response => {
        const data = await response.json()
        setName(data.name)
        setEmail(data.email)
        return data
      })
      .catch(error => console.error(error))
  }

  return (
    <StaticLayout headingText="Order completed" tw="min-h-72">
      <h2>Thanks, {name}!</h2>
      <p>We just received your order, and we'll get started right away.</p>
      <p>
        Please remember our products are hand made personally for you, so the
        creation time may vary depending on complexity and workload. We'll reach
        back to you on <strong>{email}</strong> with the estimated completion date.
      </p>
      <p>We appreciate your patience.</p>
      <div tw="mt-4">
        <Link to="/" tw="text-primary-600 hocus:text-primary-700">
          <BackIcon tw="inline-block" size="18" /> Back to Home
        </Link>
      </div>
    </StaticLayout>
  )
}

export default OrderSuccessPage
